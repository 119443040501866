.custom-sign-form {
    background: #ffffff!important;
}

.custom-input-form, .custom-input-form:focus {
    border: none!important;
    border-bottom: 1px solid #ccc!important;
    border-radius: 0!important;
    box-shadow: none!important;
}

.custom-header-login {
    font-weight: 900!important;
    letter-spacing: .2rem!important;
}

.custom-color-label {
    color: #888!important;
}