.custom-paragraph {
    font-weight: 200!important;
    font-size: 1.2rem!important;
    color: rgba(255, 255, 255, 0.4)!important;
}

.custom-div-image{
    height: 300px!important;
    background-color: #ccc!important;
    background-size: cover!important;
    background-position: center center!important;
    background-repeat: no-repeat!important;
    position: relative!important;
    overflow: hidden!important;
}

.custom-countup{
    font-weight: 900;
    color: #fff;
    margin-bottom: 20px;
    letter-spacing: .1em;
    font-size: 3rem;
}

.custom-strong-about{
    font-weight: 900!important;
}

.custom-text-about > p > i > strong,
.custom-text-about > p > strong {
    font-weight: 900!important;
}