.custom-btn-left{
    border-top-left-radius: 100px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 100px !important;
}

.custom-btn-right{
    border-top-left-radius: 0px !important;
    border-top-right-radius: 100px !important;
    border-bottom-right-radius: 100px !important;
    border-bottom-left-radius: 0px !important;
}

.custom-btn-selected {
    background-color: #27719e!important;
    border-color: #27719e!important;
    border-width: 2px!important;
}

.custom-btn-selected:hover {
    background-color: #27719e!important;
    border-color: #27719e!important;
}

.custom-btn-non-selected {
    background-color: transparent!important;
    border-color: #27719e!important;
    border-width: 2px!important;
}

.custom-btn-non-selected:hover {
    background-color: #27719e!important;
    border-color: #27719e!important;
}