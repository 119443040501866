.custom-count-down {
    top: -61px!important;
    position: relative!important;
    font-weight: 200!important;
    font-size: 1.5rem!important;
    line-height: 1!important;
}

.custom-counter-container {
    max-width: 95px!important;
}

.visible-hidden{
    visibility: hidden;
}

.visible{
    visibility: visible;
    animation-name: visible;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes visible {
    0% {
        opacity: 0.5;
        transform: translateY(50px);
    }
    50% {
        opacity: 0.7;
        transform: translateY(25px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.custom-font-wight-bolder {
    font-weight: 900 !important;
}

.custom-font-weight-lighter {
    font-weight: 200 !important;
}

.custom-count-label {
    top: -13px!important;
}

/* @media (max-width: 454px) { */
@media (max-width: 637px) {
    .small-screen-chart{
        padding-bottom: 0px!important;
        margin-bottom: 0px!important;
    }
    .small-screen-label-chart{
        padding-bottom: 3rem!important;
        margin-bottom: 3rem!important;
    }
}

/* @media (min-width: 455px) { */
@media (min-width: 638px) {
    .small-screen-chart{
        padding-bottom: 3rem!important;
        margin-bottom: 3rem!important;
    }
}