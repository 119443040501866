.custom-text-light {
    color: rgba(255, 255, 255, 0.4)!important;
    font-weight: 300!important;
}

.custom-table {
    border-bottom: .5px solid rgba(255, 255, 255, .25)!important;
}

.custom-table th, .custom-table td {
    border-top: .5px solid rgba(255, 255, 255, .25)!important;
}

.custom-option-color {
    width: 30px;
    height: 30px;
}

.custom-option-blue {
    background: #27719e!important;
}

.custom-option-black {
    background: #000!important;
}

.custom-option-silver {
    background: #d8d8d8!important;
}