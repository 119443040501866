.custom-bg-black{
    background-color: #1A1A1A!important;
}

/* @media (max-width: 991px) { */
@media (max-width: 1199px) {
    .custom-top-section {
        margin-top: 81px!important;
    }
}

/* @media (min-width: 992px) { */
@media (min-width: 1200px) {
    .custom-top-section {
        /* margin-top: 64px!important; */
        margin-top: 98px!important;
    }
}