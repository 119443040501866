.custom-bg-loader{
    background: #222;
    height: 100vh;
    position: absolute;
    width: 100%;
}

.custom-loader,
.custom-loader:before,
.custom-loader:after {
  border-radius: 50%;
}
.custom-loader {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 25vh auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.custom-loader:before,
.custom-loader:after {
  position: absolute;
  content: '';
}
.custom-loader:before {
  width: 5.2em;
  height: 10.2em;
  background: #222222;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.custom-loader:after {
  width: 5.2em;
  height: 10.2em;
  background: #222222;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}