/* The following block can be included in a custom.scss */

/* make the customizations */
// $theme-colors: (
//     "info": tomato,
//     "danger": teal
// );
$enable-gradients: true;
$enable-shadows: true;
// $navbar-dark-color:                 rgba(255,255,255, .7);
// $navbar-dark-hover-color:           rgba(255,255,255, 1);
// $navbar-light-color:                rgba(0,0,0, .7);
// $navbar-light-hover-color:          rgba(0,0,0, 1);

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
$font-family-base: 'Open Sans', sans-serif;
$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: 400;
$font-weight-bolder: 900;

$dark: #222;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";