.custom-btn {
    border-radius: 100px!important;
    text-transform: uppercase!important;
    font-size: 12px!important;
    letter-spacing: .2rem!important;
    background-color: #27719e!important;
    border-color: #27719e!important;
    color: #fff!important;
}

.custom-btn:hover {
    background-color: #03375e!important;
    border-color: #03375e!important;
}

.custom-btn-children {
    color: #fff!important;
    text-decoration: none!important;
}

.custom-header {
    color: #fff;
    font-weight: 200;
}