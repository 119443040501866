.custom-btn-dark{
    border-radius: 100px!important;
    text-transform: uppercase!important;
    font-size: 12px!important;
    letter-spacing: .2rem!important;
    background: #333!important;
    color: #fff!important;
}

.custom-btn-dark:hover{
    background: rgb(46, 46, 46)!important;
}