.btn[disabled]:hover {
  background-color: #27719e!important;
  border-color: #27719e!important;
}

.custom-bg-black {
  background-color: #1A1A1A!important;
}

.custom-h-100 {
  height: 100vh!important;
}

.custom-header-login {
  font-weight: 900!important;
  letter-spacing: .2rem!important;
}

.custom-bg-image {
  height: 100%!important;
  width: 100%!important;
  background-color: #ccc!important;
  background-size: cover!important;
  background-position: center center!important;
  background-repeat: no-repeat!important;
  position: absolute!important;
  overflow: hidden!important;
  bottom: 0!important;
  top: 0!important;
  left: 0!important;
  right: 0!important;
}

.spinner {
  width: 80px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.spinner>div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}