.custom-list,
.custom-list li{
    padding: 0!important;
    margin: 0!important;
}

.custom-list li {
    list-style: none!important;
    margin-bottom: 20px!important;
    color: rgba(255, 255, 255, 0.4)!important;
    font-weight: 300!important;
}

.custom-list li > span {
    color: #27719e!important;
    letter-spacing: .1em!important;
    font-size: 12px!important;
    font-weight: 500!important;
    margin-bottom: 5px!important;
    display: block!important;
}