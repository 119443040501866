.custom-bg-transparent {
    background-color: transparent!important;
}

.custom-bg-white {
    /* background-color: #222!important; */
    background-color: #fff!important;
}

.custom-init-section {
    height: 100vh;
    min-height: 800px;
    background-color: #ccc;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.custom-letter-spacing {
    letter-spacing: .2em!important;
}

.custom-font-14 {
    font-size: 14px!important;
}

.custom-top-20 {
    top: 20px!important;
    z-index: 999;
}