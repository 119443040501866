.custom-init-section {
    height: 100vh;
    min-height: 800px;
    background-color: #ccc;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.custom-init-section .row {
    height: 100vh;
    min-height: 800px;
}

@media (min-width: 768px) {
    .custom-init-section h1 {
        font-size: 70px;
    }
}

.custom-init-section h1 {
    line-height: 1.2;
}

.custom-font-wight-bolder {
    font-weight: 900 !important;
}

.custom-font-weight-lighter {
    font-weight: 200 !important;
}

/* @media (max-width: 991px) {
    .custom-init-section h1 {
        position: relative!important;
        top: -56px!important;
    }
}

@media (min-width: 992px) {
    .custom-init-section h1 {
        position: relative!important;
        top: -64px!important;
    }
} */