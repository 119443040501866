/* @media (max-width: 991px) { */
@media (max-width: 1199px) {
    .custom-logo {
        display: none!important;
    }
    .navbar-dark .custom-border-bottom-navbar-item {
        border-bottom: .5px solid rgba(255, 255, 255, .25)!important;
    }
    .navbar-light .custom-border-bottom-navbar-item {
        border-bottom: .5px solid rgba(0, 0, 0, .25)!important;
    }
}

/* @media (min-width: 992px) { */
@media (min-width: 1200px) {
    .custom-logo-collapse {
        display: none!important;
    }
    .custom-border-bottom-light {
        border-bottom: none;
    }
}

.custom-active {
    color: #27719e!important;
}