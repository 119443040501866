.custom-text-light {
    color: rgba(255, 255, 255, 0.4)!important;
    font-weight: 300!important;
}

.custom-price {
    color: #fff!important;
    font-weight: 900!important;
}

.custom-btn-secondary {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #333;
    position: relative;
    border-radius: 50%;
    font-size: 18px;
    margin-right: 15px;
    color: #27719e!important;
}

.custom-btn-secondary>span {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.custom-btn-secondary:hover {
    background: rgb(46, 46, 46)!important;
}

.custom-small-indicator{
    font-size: 0.8rem!important;
}