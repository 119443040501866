.custom-form-item {
    border: none!important;
    background-color: #333!important;
    color: #fff!important;
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1) !important;
}

.custom-form-item:focus {
    background: #404040!important;
}

.custom-error-message {
    color: #D63447!important;
    font-weight: 300!important;
}

.custom-success-message {
    color: #28a745!important;
    font-weight: 300!important;
}

.custom-placeholder {
    color: #6c757d!important;
}