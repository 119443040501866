.custom-text-id {
    color: rgba(255, 255, 255, 0.4)!important;
    font-weight: 200!important;
    font-size: 12px!important;
}

.custom-label-img {
    color: #fff!important;
    font-weight: 200!important;
    font-size: 20px!important;
}

.custom-button-details {
    font-size: 12px!important;
    text-transform: uppercase!important;
    color: #cccccc!important;
}

.custom-link {
    cursor: pointer!important;
}

.custom-link:hover {
    text-decoration: underline;
}

.custom-detail {
    cursor: pointer!important;
    text-decoration: none!important;
    color: rgb(255, 255, 255, 0.4)!important;
    cursor: pointer!important;
}

.custom-detail span {
    transition: all .25s ease-in-out;
}

.custom-detail:hover span {
    margin-left: 20px!important;
}

.custom-card-service {
    background-color: #333!important;
    padding: 30px!important;
    border: 4px solid transparent!important;
    border-radius: 7px!important;
}

.custom-card-service-title {
    font-weight: 200!important;
    color: #27719e!important;
    font-size: 20px!important;
}

.custom-card-service-description {
    color: rgba(255, 255, 255, 0.4)!important;
    font-weight: 300!important;
    line-height: 1.7!important;
}

.custom-span-transition {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #27719e;
    /* margin-left: -100%; */
    z-index: 9;
}

.custom-position-img {
    transform: translateX(-100%);
}

.custom-transition-background {
    animation-name: translateBackground;
    animation-duration: .5s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes translateBackground {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(-85%);
    }
    100% {
        transform: translateX(0%);
    }
}

.custom-transition-img {
    animation-name: translateImg;
    animation-duration: .5s;
    animation-delay: .75s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
}

@keyframes translateImg {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(-85%);
    }
    100% {
        transform: translateX(0%);
    }
}

.custom-transition-scale {
    animation-name: translateScale;
    animation-duration: 1.25s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
}

@keyframes translateScale {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.custom-visible {
    visibility: visible!important;
}

.custom-visible-hidden {
    visibility: hidden!important;
}

.custom-hr {
    width: 80%!important;
    border: none!important;
    border-bottom: .5px solid rgba(255, 255, 255, .25)!important;
}

.img-scale {
    transition: transform .25s ease-in-out;
    cursor: pointer!important;
    background-color: rgb(39, 113, 158);
}

.img-scale:hover {
    transform: scale(1.25)!important;
}

.custom-price-card{
    display: block!important;
    font-size: 12px!important;
    font-weight: 700!important;
    color: #fff!important;
}