.custom-card-icon {
    display: block!important;
    margin-bottom: 50px!important;
    background: #27719e!important;
    width: 100px!important;
    height: 100px!important;
    position: relative!important;
    border-radius: 50%!important;
    margin: 0 auto 30px auto!important;
}

.custom-card-icon span{
    font-size: 40px;
    color: #080b0e;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
}

.custom-card-title {
    font-size: 20px!important;
    font-weight: 200!important;
    color: #fff!important;
}