.custom-title-404{
    font-size: 5rem!important;
    font-weight: 900!important;
}

.custom-subtitle-404{
    font-weight: 200!important;
}

.custom-description-404{
    font-weight: 200!important;
    color: rgba(255,255,255,0.4)!important;
}